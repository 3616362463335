import React, {useState, useEffect} from 'react';
import {useParams } from 'react-router-dom';
import {getWidgetData} from "../utils/actions";
import { Tooltip } from 'react-tooltip'
import { Grid } from 'react-loader-spinner'

import laufImg from "../assets/img/lauf.png"
import logo from "../assets/img/logo.png"
import 'react-tooltip/dist/react-tooltip.css'
import LeafIcon from "./leafIcon";

const leafColors = [
  {id: 1, color: "#4FC878", secondColor: "#A7EEBF"},
  {id: 2, color: "#FD974F", secondColor: "#FFCEAC"},
  {id: 3, color: "#4433CD", secondColor: "#E3C9FC"}
];

const Widget = () => {
  const { companyId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [close, setClose] = useState(false);

  useEffect(() => {
    setLoading(true);

    (async () => {
      var fetchedAd = await getWidgetData(companyId)
      if (fetchedAd.data && fetchedAd.data.results) {
        setData(fetchedAd.data.results)
      }
    })();

    setLoading(false);
  },[])

  if(isLoading || !data) {
    return <div className="widget">
      <Grid
        height="50"
        width="30"
        color="#009448"
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>;
  }

  return (
    <div className={`widget ${close ? 'close' : ''}`}>
      <div style={{ backgroundImage: `url(${close ? laufImg : logo})` }} onClick={() => close ? setClose(false) : '' } className="column logo" />
      <div className="column leaves">
        {data && data.green_step_category.map((item, index) =>
          <div className="leaf_item" key={"secondRow_laufs"+index}>
            <LeafIcon
              id={"tooltip_leaf_item"+index}
              key={"leaf_item"+index}
              colors={leafColors.find(color => color.id === item.id)}
              percentage={item.total_category_green_steps > 0 ? item.total_category_green_steps/100 : 0}
              data-tooltip-id="leaf-item-tooltip"
              data-tooltip-place="bottom"
              data-tooltip-content={`${item.name} - ${item.total_category_green_steps}%`}
            />
          </div>
        )}
      </div>
      <Tooltip id="leaf-item-tooltip" style={{ backgroundColor: "#009448", color: "#fff", fontSize: '12px', padding: '5px 10px' }}/>
      <div className="closeWidget" onClick={() => setClose(true)}>+</div>
      <a href={data && data.page_link ? data.page_link : '#'} target="_blank" rel="noopener noreferrer" className="link"/>
    </div>

  );
}

export default Widget;
