import axios from 'axios';
// const base = 'http://laufid-env.eba-3hsi3ujn.eu-west-2.elasticbeanstalk.com/api'
const base = 'https://api.laufid.is/api'


const instance = axios.create({
  baseURL: base,
  headers: {
    "Content-Type": 'application/json',
    "Accept": 'application/json',
    'APP-KEY': '3f8a97e6-51ea-44d7-a9c9-87ae6040f7fb',
  }
});

instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (!axios.isCancel(error)) {
      return Promise.reject(error);
  }
});

export default instance;