import React from 'react';
import {createBrowserRouter, RouterProvider, BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import Widget from "./components/widget";

const router = createBrowserRouter([
  {
    path: "/company/progress/:companyId",
    element: <Widget/>,
  },
  {
    path: "*",
    element: <div id="error-page"><h1>Oops!</h1><p>Sorry, an unexpected error has occurred.</p></div>,
  },
]);


function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
